import firebase from 'firebase/app';
import 'firebase/firestore';

export default {
  addItem({
    commit,
    rootGetters
  }, item) {
    return new Promise((resolve, reject) => {
      const categoriaId = item.categoriaId
      delete item.categoriaId

      firebase
        .firestore()
        .collection('estabelecimentos')
        .doc(rootGetters.establishmentId)
        .collection('categorias')
        .doc(categoriaId)
        .collection('produtos')
        .add(item)
        .then((response) => {
          // ADD TO UNSHIFT THE PRODUCTS
          item.id = response.id
          item.categoriaId = categoriaId

          commit('ADD_ITEM', item)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchProductItems({
    commit,
    rootGetters
  }, menuId) {
    return new Promise((resolve, reject) => {
      const firestoreRef = firebase
        .firestore()
        .collection('estabelecimentos')
        .doc(rootGetters.establishmentId);

      firestoreRef
        .collection('categorias')
        .orderBy('ordenar')
        .where('tipo-cardapio', '==', menuId)
        .where('removido', '==', false)
        .get()
        .then((snapshot) => {

          const products = []

          if (!snapshot.empty) {

            snapshot.forEach(doc => {

              // RETURN CATEGORIES AND PRODUCTS
              const productItems = []

              firestoreRef
                .collection('categorias')
                .doc(doc.id)
                .collection('produtos')
                .where('removido', '==', false)
                .get()
                .then(snapshotP => {

                  if (!snapshotP.empty) {
                    snapshotP.forEach(docP => {
                      productItems.push({
                        id: docP.id,
                        nome: docP.data().nome,
                        descricao: docP.data().descricao,
                        imagem: docP.data().imagem,
                        preco: docP.data().preco,
                        ativo: docP.data().ativo,
                        categoriaId: doc.id
                      });
                    });
                  }

                  products.push({
                    categoriaId: doc.id,
                    categoriaNome: doc.data().nome,
                    categoriaAtivo: doc.data().ativo,
                    items: productItems
                  });

                  commit('SET_PRODUCTS', products)
                  resolve(snapshot)
                })
                .catch((error) => {
                  reject(error)
                })
            });
          } else {
            commit('SET_PRODUCTS', products)
            resolve(snapshot)
          }
        })
        .catch((error) => {
          reject(error)
        })
    });
  },
  updateItem({
    commit,
    rootGetters
  }, item) {
    return new Promise((resolve, reject) => {
      const itemId = item.id
      delete item.id
      const categoriaId = item.categoriaId
      delete item.categoriaId

      firebase
        .firestore()
        .collection('estabelecimentos')
        .doc(rootGetters.establishmentId)
        .collection('categorias')
        .doc(categoriaId)
        .collection('produtos')
        .doc(itemId)
        .update(item)
        .then((response) => {

          // UPDATE TO ASSIGN THE PRODUCTS
          item.id = itemId
          item.categoriaId = categoriaId

          commit('UPDATE_ITEM', item)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeItem({
    commit,
    rootGetters
  }, item) {
    return new Promise((resolve, reject) => {
      if (typeof item.imagem !== 'undefined' && item.imagem !== null) {
        firebase
          .storage()
          .ref()
          .child(item.imagem.replace('gs://emennu-d70eb.appspot.com/', ''))
          .delete()
          .then()
          .catch((error) => {
            console.log(error);
          })
      }

      firebase
        .firestore()
        .collection('estabelecimentos')
        .doc(rootGetters.establishmentId)
        .collection('categorias')
        .doc(item.categoriaId)
        .collection('produtos')
        .doc(item.id)
        .delete()
        .then((response) => {
          commit('REMOVE_ITEM', item)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  changeStatusItem({
    commit,
    rootGetters
  }, item) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('estabelecimentos')
        .doc(rootGetters.establishmentId)
        .collection('categorias')
        .doc(item.categoriaId)
        .collection('produtos')
        .doc(item.id)
        .update({
          ativo: item.ativo
        })
        .then((response) => {
          commit('CHANGE_STATUS_ITEM', item)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
};
