export default {
  ADD_ITEM(state, item) {
    delete item.ordenar
    delete item.removido
    const categoryIndex = state.products.findIndex((p) => p.categoriaId === item.categoriaId)
    state.products[categoryIndex].items.unshift(item)
    state.products[categoryIndex].update = true
  },
  SET_PRODUCTS(state, products) {
    state.products = products
  },
  UPDATE_ITEM(state, item) {
    const categoryIndex = state.products.findIndex((p) => p.categoriaId === item.categoriaId)
    const productIndex = state.products[categoryIndex].items.findIndex((p) => p.id === item.id)
    Object.assign(state.products[categoryIndex].items[productIndex], item)
  },
  REMOVE_ITEM(state, item) {
    const categoryIndex = state.products.findIndex((p) => p.categoriaId === item.categoriaId)
    const productIndex = state.products[categoryIndex].items.findIndex((p) => p.id === item.id)
    state.products[categoryIndex].items.splice(productIndex, 1)
  },
  CHANGE_STATUS_ITEM(state, item) {
    const categoryIndex = state.products.findIndex((p) => p.categoriaId === item.categoriaId)
    const productIndex = state.products[categoryIndex].items.findIndex((p) => p.id === item.id)
    Object.assign(state.products[categoryIndex].items[productIndex], item)
  }
}
