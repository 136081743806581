<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ !this.data.id ? "ADICIONAR" : "ATUALIZAR" }} PRODUTO</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component
      :is="scrollbarTag"
      class="scroll-area--data-list-add-new"
      :settings="settings"
      :key="$vs.rtl"
    >
      <div class="p-6">
        <!-- CATEGORY -->
        <vs-select v-model="dataCategoryId" label="Categoria" class="mt-5 w-full">
          <vs-select-item key="0" value="0" text="Selecione" />
          <vs-select-item
            :key="item.id"
            :value="item.id"
            :text="item.nome"
            v-for="item in categories"
          />
        </vs-select>

        <!-- NAME -->
        <vs-input
          label="Nome do Produto"
          v-model="dataName"
          class="mt-5 w-full"
          name="item-name"
          v-validate="'required'"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('item-name')"
        >{{ errors.first('item-name') }}</span>

        <!-- DESCRIPTION -->
        <vs-textarea
          label="Descrição"
          v-model="dataDescription"
          class="mt-5 w-full"
          name="item-name"
        />

        <!-- PRICE -->
        <vs-input
          icon-pack="feather"
          icon="icon-dollar-sign"
          label="Preço"
          v-model="dataPrice"
          class="mt-5 w-full"
          v-validate="{ required: true, regex: /\d+(\.\d+)?$/ }"
          name="item-price"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('item-price')"
        >{{ errors.first('item-price') }}</span>

        <!-- STATUS -->
        <vs-checkbox color="success" class="inline-flex mt-5 w-full" v-model="dataStatus">Ativar</vs-checkbox>

        <!-- Image -->
        <template v-if="dataImg || dataCaminhoImg">
          <!-- Image Container -->
          <div class="img-container w-64 mx-auto flex items-center justify-center">
            <template v-if="dataId && !dataImgBase64">
              <image-view :pathImage="dataCaminhoImg" class-name="responsive" />
            </template>
            <template v-else>
              <img :src="dataImg" alt="img" class="responsive" />
            </template>
          </div>

          <!-- Image Progress -->
          <div class="w-64 mx-auto flex items-center justify-center mt-5 w-full">
            <vs-progress :percent="percentUpload" color="success"></vs-progress>
          </div>

          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-between mt-5">
            <input
              type="file"
              class="hidden"
              ref="updateImgInput"
              @change="updateCurrImg"
              accept="image/*"
            />
            <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">Alterar Imagem</vs-button>
            <vs-button
              type="flat"
              color="#999"
              @click="dataImg = null, dataCaminhoImg = null"
            >Remover Imagem</vs-button>
          </div>
        </template>

        <div class="upload-img mt-5" v-if="!dataImg && !dataCaminhoImg">
          <input
            type="file"
            class="hidden"
            ref="uploadImgInput"
            @change="updateCurrImg"
            accept="image/*"
          />
          <vs-button @click="$refs.uploadImgInput.click()">Escolher Imagem</vs-button>
        </div>
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Salvar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ImageView from './../../components/ImageView.vue'

import firebase from 'firebase/app'
import 'firebase/storage'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => { }
    }
  },
  components: {
    VuePerfectScrollbar,
    ImageView
  },
  data() {
    return {

      dataId: null,
      dataName: '',
      dataCategoryId: '0',
      dataDescription: '',
      dataPrice: 0,
      dataImg: null,
      dataImgBase64: null,
      dataCaminhoImg: '',
      percentUpload: 0,
      dataStatus: true,

      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        const { id, nome, descricao, preco, categoriaId, imagem, ativo } = JSON.parse(JSON.stringify(this.data))
        this.dataId = id
        this.dataName = nome
        this.dataDescription = descricao
        this.dataPrice = this.formatPrice(preco)
        this.dataCategoryId = categoriaId
        this.dataCaminhoImg = imagem
        this.dataStatus = ativo
        //this.initValues()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    isFormValid() {
      return !this.errors.any() && this.dataName && this.dataCategoryId !== '0'
    },
    scrollbarTag() { return this.$store.getters.scrollbarTag },
    categories() {
      return this.$store.state.moduleCategory.categories
    }
  },
  methods: {
    initValues() {
      if (this.data.id) return
      this.dataId = null
      this.dataName = ''
      this.dataDescription = ''
      this.dataPrice = 0
      this.dataCategoryId = '0'
      this.dataImg = null
      this.dataImgBase64 = null
      this.dataCaminhoImg = ''
      this.dataStatus = true
    },
    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.onUpload().then((response) => {
            if (response) {
              const obj = {
                id: this.dataId,
                nome: this.dataName,
                descricao: this.dataDescription,
                preco: Number(parseFloat(this.dataPrice.replace(',', '.'))),
                imagem: (typeof this.dataCaminhoImg === 'undefined') ? '' : this.dataCaminhoImg,
                ordenar: 1,
                ativo: this.dataStatus,
                removido: false,
                categoriaId: this.dataCategoryId
              }

              if (this.dataId !== null) {
                this.$store.dispatch('moduleProduct/updateItem', obj).catch(err => { console.error(err) })
              } else {
                delete obj.id
                this.$store.dispatch('moduleProduct/addItem', obj).catch(err => { console.error(err) })
              }

              this.$vs.notify({
                color: 'success',
                title: 'Produto',
                text: `Item ${this.dataId !== null ? 'alterado' : 'inserido'} com sucesso`
              })

              this.$emit('closeSidebar')
              this.initValues()
            }
          })
        }
      })
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    onUpload() {
      return new Promise((resolve, reject) => {
        if (this.dataImgBase64) {
          const storageRef = firebase.storage().ref(`produtos/thefifties/${this.dataImgBase64.name}`).put(this.dataImgBase64);

          storageRef.on(`state_changed`, snapshot => {
            this.percentUpload = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
            (error) => {
              reject(error)
            },
            () => {
              this.percentUpload = 100;
              this.dataCaminhoImg = `gs://${storageRef.snapshot.ref.location.bucket}/${storageRef.snapshot.ref.location.path_}`
              resolve(true)
            }
          );
        } else {
          resolve(true)
        }
      })
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.dataImgBase64 = input.target.files[0]

        const reader = new FileReader()
        reader.onload = e => {
          this.dataImg = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
