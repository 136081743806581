<template>
  <div id="data-list-list-view" class="data-list-container">
    <data-view-sidebar-category
      :isSidebarActive="addNewDataSidebarCategory"
      @closeSidebar="toggleDataSidebarCategory"
      :data="sidebarDataCategory"
    />
    <data-view-sidebar
      :isSidebarActive="addNewDataSidebar"
      @closeSidebar="toggleDataSidebar"
      :data="sidebarData"
    />

    <vs-table ref="table" pagination :max-items="itemsPerPage" :data="products">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- ACTION - DROPDOWN -->
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4">
            <div
              class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full"
            >
              <span class="mr-2">Cardápios</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item
                :key="item.id"
                :value="item.id"
                :text="item.nome"
                v-for="item in menus"
                :to="{ name: 'product-list-view', params: { menuId: item.id }}"
              >
                <span class="flex items-center">
                  <feather-icon
                    v-if="item.id === menuId"
                    icon="CheckSquareIcon"
                    svgClasses="h-4 w-4"
                  />
                  <span :class="item.id === menuId ? 'ml-2' : 'ml-6'">{{ item.nome }}</span>
                </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <!-- ADD NEW -->
          <div
            class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
            @click.stop="addNewDataCategory"
          >
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">Adicionar Categoria</span>
          </div>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span
              class="mr-2"
            >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ products.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : products.length }} de {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage=4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="name" class="w-full">Categorias</vs-th>
        <vs-th></vs-th>
        <!-- <vs-th></vs-th> -->
      </template>

      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td class="w-full">
              <p class="product-name font-medium truncate">{{ tr.categoriaNome }}</p>
            </vs-td>
            <vs-td>
              <vx-tooltip
                :text="`Clique para ${tr.categoriaAtivo ? 'INATIVAR' : 'ATIVAR'}`"
                position="top"
              >
                <vs-chip
                  :color="getStatusColor(tr.categoriaAtivo)"
                  class="product-order-status stroke-current"
                >
                  <div
                    @click.stop="changeStatusDataCategory(tr)"
                  >{{ getStatusTitle(tr.categoriaAtivo) }}</div>
                </vs-chip>
              </vx-tooltip>
            </vs-td>

            <vs-td>
              <feather-icon
                icon="EditIcon"
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
                @click.stop="editDataCategory(tr)"
              />
            </vs-td>

            <template
              class="expand-user w-full"
              slot="expand"
              v-if="tr.items && tr.items.length > 0"
            >
              <tbody>
                <vs-tr
                  :data="trP"
                  :key="indextrP"
                  v-for="(trP, indextrP) in tr.items"
                  class="con-expand-users w-full"
                >
                  <vs-td class="img-container">
                    <image-view :pathImage="trP.imagem" class-name="product-img" />
                  </vs-td>

                  <vs-td class="w-5/6">
                    <p class="product-name font-medium truncate">{{ trP.nome }}</p>
                    <p>{{ trP.descricao }}</p>
                  </vs-td>

                  <vs-td class="whitespace-no-wrap">
                    <p class="product-price">R$ {{ formatPrice(trP.preco) }}</p>
                  </vs-td>

                  <vs-td class="whitespace-no-wrap">
                    <vx-tooltip
                      :text="`Clique para ${trP.ativo ? 'INATIVAR' : 'ATIVAR'}`"
                      position="top"
                    >
                      <vs-chip
                        :color="getStatusColor(trP.ativo)"
                        class="product-order-status stroke-current"
                      >
                        <div @click="changeStatusData(trP)">{{ getStatusTitle(trP.ativo) }}</div>
                      </vs-chip>
                    </vx-tooltip>
                  </vs-td>

                  <vs-td class="whitespace-no-wrap">
                    <feather-icon
                      icon="EditIcon"
                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                      @click.stop="editData(trP)"
                    />
                    <feather-icon
                      icon="TrashIcon"
                      svgClasses="w-5 h-5 hover:text-danger stroke-current"
                      class="ml-2"
                      @click.stop="openConfirm(trP)"
                    />
                  </vs-td>
                </vs-tr>

                <vs-tr>
                  <vs-td colspan="5">
                    <!-- ADD NEW ITEM -->
                    <div
                      class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
                      @click.stop="addNewData(tr.categoriaId)"
                    >
                      <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                      <span class="ml-2 text-base text-primary">Adicionar Item</span>
                    </div>
                  </vs-td>
                </vs-tr>
              </tbody>
            </template>

            <template slot="expand" class="expand-user w-full" v-else>
              <tbody>
                <vs-tr class="con-expand-users w-full">
                  <vs-td class="w-full">Nenhum item cadastrado</vs-td>
                </vs-tr>
                <vs-tr class="con-expand-users w-full">
                  <vs-td class="w-full">
                    <!-- ADD NEW ITEM -->
                    <div
                      class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
                      @click.stop="addNewData(tr.categoriaId)"
                    >
                      <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                      <span class="ml-2 text-base text-primary">Adicionar Item</span>
                    </div>
                  </vs-td>
                </vs-tr>
              </tbody>
            </template>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import DataViewSidebarCategory from '../../category/DataViewSidebar.vue'
import DataViewSidebar from '../DataViewSidebar.vue'
import moduleMenu from '@/store/menu/moduleMenu.js'
import moduleCategory from '@/store/category/moduleCategory.js'
import moduleProduct from '@/store/product/moduleProduct.js'
import ImageView from './../../../components/ImageView.vue'

export default {
  components: {
    DataViewSidebarCategory,
    DataViewSidebar,
    ImageView
  },

  data() {
    return {
      menuId: this.$route.params.menuId,
      itemsPerPage: 10,
      isMounted: false,

      // Data Sidebar Category
      addNewDataSidebarCategory: false,
      sidebarDataCategory: {},

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {}
    }
  },

  watch: {
    $route(to) {
      this.menuId = to.params.menuId;
      this.loadProducts();
    }
  },

  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    products() {
      return this.$store.state.moduleProduct.products
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.products.length
    },
    menus() {
      return this.$store.state.moduleMenu.menus
    }
  },

  methods: {
    addNewDataCategory() {
      this.sidebarDataCategory = {}
      this.toggleDataSidebarCategory(true)
    },
    addNewData(categoryId) {
      this.sidebarData = { id: null, categoriaId: categoryId, descricao: '', preco: 0, ativo: false }
      this.toggleDataSidebar(true)
    },
    deleteData(data) {
      const obj = {
        id: data.id,
        categoriaId: data.categoriaId,
        imagem: data.imagem
      }
      this.$store.dispatch('moduleProduct/removeItem', obj).catch(err => { console.error(err) })
    },
    changeStatusDataCategory(data) {
      const obj = {
        id: data.categoriaId,
        ativo: !data.categoriaAtivo
      }
      this.$store.dispatch('moduleCategory/changeStatusItem', obj).catch(err => { console.error(err) })
    },
    changeStatusData(data) {
      const obj = {
        id: data.id,
        categoriaId: data.categoriaId,
        ativo: !data.ativo
      }
      this.$store.dispatch('moduleProduct/changeStatusItem', obj).catch(err => { console.error(err) })
    },
    editDataCategory(data) {
      const obj = {
        id: data.categoriaId,
        nome: data.categoriaNome,
        ativo: data.categoriaAtivo,
        tipoCardapioId: this.menuId
      }
      this.sidebarDataCategory = obj
      this.toggleDataSidebarCategory(true)
    },
    editData(data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    getStatusColor(status) {
      if (status) return 'success'
      return 'danger'
    },
    getStatusTitle(status) {
      if (status) return 'ATIVO'
      return 'INATIVO'
    },
    toggleDataSidebarCategory(val = false) {
      this.addNewDataSidebarCategory = val
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    openConfirm(item) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmação',
        text: `Deseja realmente excluir o item ${item.nome}?`,
        accept: this.acceptAlert,
        parameters: item
      })
    },
    acceptAlert(parameters) {
      this.deleteData(parameters)
    },
    loadProducts() {
      // MODULE PRODUCT
      if (!moduleProduct.isRegistered) {
        this.$store.registerModule('moduleProduct', moduleProduct)
        moduleProduct.isRegistered = true
      }
      this.$store.dispatch('moduleProduct/fetchProductItems', this.menuId)
    }
  },

  created() {
    // MODULE PRODUCT
    this.loadProducts()

    // MODULE CATEGORY
    if (!moduleCategory.isRegistered) {
      this.$store.registerModule('moduleCategory', moduleCategory)
      moduleCategory.isRegistered = true
    }
    this.$store.dispatch('moduleCategory/fetchCategoryItems')

    // MODULE MENU
    if (!moduleMenu.isRegistered) {
      this.$store.registerModule('moduleMenu', moduleMenu)
      moduleMenu.isRegistered = true
    }
    this.$store.dispatch('moduleMenu/fetchMenuItems')
  },

  mounted() {
    this.isMounted = true
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
          &.img-container {
            // width: 1rem;
            // background: #fff;

            span {
              display: flex;
              justify-content: flex-start;
            }

            .product-img {
              height: 80px;
            }
          }
        }
        td.td-check {
          padding: 20px !important;
        }
        td.td-check.active-expanded {
          width: 55px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      th.td-check.active-expanded {
        width: 55px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
