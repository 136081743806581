export default {
  ADD_ITEM(state, item) {
    state.categories.unshift(item)
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories
  },
  // SET_LABELS(state, labels) {
  //   state.eventLabels = labels
  // },
  UPDATE_ITEM(state, category) {
    const categoryIndex = state.categories.findIndex((p) => p.id === category.id)
    Object.assign(state.categories[categoryIndex], category)
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.categories.findIndex((p) => p.id === itemId)
    state.categories.splice(ItemIndex, 1)
  },
  CHANGE_STATUS_ITEM(state, category) {
    const categoryIndex = state.categories.findIndex((p) => p.id === category.id)
    Object.assign(state.categories[categoryIndex], category)
  }
}
