<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ Object.entries(this.data).length === 0 ? "ADICIONAR" : "ATUALIZAR" }} CATEGORIA</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component
      :is="scrollbarTag"
      class="scroll-area--data-list-add-new"
      :settings="settings"
      :key="$vs.rtl"
    >
      <div class="p-6">
        <!-- MENU -->
        <!-- <vs-select v-model="dataMenuId" label="Cardápio" class="mt-5 w-full">
          <vs-select-item key="0" value="0" text="Selecione" />
          <vs-select-item :key="item.id" :value="item.id" :text="item.nome" v-for="item in menus" />
        </vs-select>-->

        <!-- NAME -->
        <vs-input
          label="Nome da Categoria"
          v-model="dataName"
          class="mt-5 w-full"
          name="item-name"
          v-validate="'required'"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('item-name')"
        >{{ errors.first('item-name') }}</span>

        <!-- STATUS -->
        <vs-checkbox color="success" class="inline-flex mt-5 w-full" v-model="dataStatus">Ativar</vs-checkbox>
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Salvar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => { }
    }
  },
  components: {
    VuePerfectScrollbar
  },
  data() {
    return {
      dataMenuId: this.$route.params.menuId,
      dataId: null,
      dataName: '',
      dataStatus: true,

      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        const { id, nome, tipoCardapioId, ativo } = JSON.parse(JSON.stringify(this.data))
        this.dataId = id
        this.dataName = nome
        this.dataMenuId = tipoCardapioId
        this.dataStatus = ativo
        //this.initValues()
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    isFormValid() {
      return !this.errors.any() && this.dataName && this.dataMenuId
    },
    scrollbarTag() { return this.$store.getters.scrollbarTag },
    menus() {
      return this.$store.state.moduleMenu.menus
    }
  },
  methods: {
    initValues() {
      if (this.data.id) return
      this.dataId = null
      this.dataName = ''
      //this.dataMenuId = '0'
      this.dataStatus = true
    },
    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          const obj = {
            id: this.dataId,
            nome: this.dataName,
            ordenar: 1,
            ativo: this.dataStatus,
            removido: false,
            'tipo-cardapio': this.dataMenuId
            // Return Name Menu
            // tipoCardapioNome: this.menus.find(
            //   x => Number(x.id) === Number(this.dataMenuId)
            // ).nome
          }

          if (this.dataId !== null) {
            this.$store.dispatch('moduleCategory/updateItem', obj).catch(err => { console.error(err) })
          } else {
            delete obj.id
            this.$store.dispatch('moduleCategory/addItem', obj).catch(err => { console.error(err) })
          }

          this.$emit('closeSidebar')
          this.initValues()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
