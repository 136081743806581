import firebase from 'firebase/app';
import 'firebase/firestore';

export default {
  addItem({
    commit,
    rootGetters
  }, item) {
    return new Promise((resolve, reject) => {
      const tipoCardapioNome = item.tipoCardapioNome
      delete item.tipoCardapioNome

      firebase
        .firestore()
        .collection('estabelecimentos')
        .doc(rootGetters.establishmentId)
        .collection('categorias')
        .add(item)
        .then((response) => {

          // ADD TO UNSHIFT THE CATEGORIES
          item.tipoCardapioId = item['tipo-cardapio']
          item.tipoCardapioNome = tipoCardapioNome

          commit('ADD_ITEM', Object.assign(item, {
            id: response.id
          }))
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCategoryItems({
    commit,
    rootGetters
  }) {
    return new Promise((resolve, reject) => {
      const firestoreRef = firebase
        .firestore()
        .collection('estabelecimentos')
        .doc(rootGetters.establishmentId);

      // FETCH MENU ITEMS
      const menus = [];
      firestoreRef
        .collection('tipos-cardapio')
        .get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            menus.push({
              id: Number(doc.id),
              nome: doc.data().nome
            });
          });
        })
        .catch(error => {
          reject(error);
        });

      firestoreRef
        .collection('categorias')
        .where('removido', '==', false)
        .orderBy('tipo-cardapio')
        .orderBy('ordenar')
        .get()
        .then(snapshot => {

          const categories = [];
          snapshot.forEach(doc => {

            let tipoCardapioNome = 'N/I'
            if (menus.find(x => x.id === Number(doc.data()['tipo-cardapio']))) {
              tipoCardapioNome = menus.find(
                x => x.id === Number(doc.data()['tipo-cardapio'])
              ).nome;
            }

            categories.push({
              id: doc.id,
              nome: doc.data().nome,
              ativo: doc.data().ativo,
              tipoCardapioId: doc.data()['tipo-cardapio'],
              tipoCardapioNome
            });
          });
          commit('SET_CATEGORIES', categories);
          resolve(snapshot);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateItem({
    commit,
    rootGetters
  }, item) {
    return new Promise((resolve, reject) => {
      const itemId = item.id
      delete item.id
      const tipoCardapioNome = item.tipoCardapioNome
      delete item.tipoCardapioNome

      firebase
        .firestore()
        .collection('estabelecimentos')
        .doc(rootGetters.establishmentId)
        .collection('categorias')
        .doc(itemId)
        .update(item)
        .then((response) => {

          // UPDATE TO ASSIGN THE CATEGORIES
          item.id = itemId
          item.tipoCardapioId = item['tipo-cardapio']
          item.tipoCardapioNome = tipoCardapioNome

          commit('UPDATE_ITEM', item)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeItem({
    commit,
    rootGetters
  }, itemId) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('estabelecimentos')
        .doc(rootGetters.establishmentId)
        .collection('categorias')
        .doc(itemId)
        .update({
          removido: true
        })
        .then((response) => {
          commit('REMOVE_ITEM', itemId)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  changeStatusItem({
    commit,
    rootGetters
  }, item) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('estabelecimentos')
        .doc(rootGetters.establishmentId)
        .collection('categorias')
        .doc(item.id)
        .update({
          ativo: item.ativo
        })
        .then((response) => {
          commit('CHANGE_STATUS_ITEM', item)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
};
